export const getOverviewData = () => {
  return [
    {
      sectionImage: "Overview/allegiant-flight-deck-logo.svg",
      paragraph:
        "Broad and inviting, this space features a customizable blank canvas primed for a variety of event set-ups and styles.",
      area: "24,000",
      reception: "1,200",
      //banquet: '400'
    },
  ];
};

export const getCarouselImages = () => {
  return [
    // "AllegiantFlightDeck/Photos/flight-deck-bg.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-02.jpg",
    "AllegiantFlightDeck/Photos/flight-deck-01.jpg",
    "AllegiantFlightDeck/Photos/flight-deck-02.jpg",
    "AllegiantFlightDeck/Photos/flight-deck-03.jpg",
    "AllegiantFlightDeck/Photos/flight-deck-04.jpg",
  ];
};

export const getFieldViewImages = () => {
  return [
    "AllegiantFlightDeck/FieldView/VR_Section_101_32_12_Black.jpg",
    "AllegiantFlightDeck/FieldView/VR_Section_101_32_12_Down.jpg",
    "AllegiantFlightDeck/FieldView/VR_Section_101_32_12_Front.jpg",
    "AllegiantFlightDeck/FieldView/VR_Section_101_32_12_Left.jpg",
    "AllegiantFlightDeck/FieldView/VR_Section_101_32_12_Right.jpg",
    "AllegiantFlightDeck/FieldView/VR_Section_101_32_12_Up.jpg",
  ];
};

export const getLayoutImages = () => {
  return ["AllegiantFlightDeck/Layout/layout-allegiant-flight-deck.jpg"];
};
